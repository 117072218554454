import React, { useState } from 'react';
import withLayout from 'layout';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useGetOrderConfigQuery, useGetOrderByIdQuery } from 'api/orderForm';
import { ModeState } from 'store/features/orderForm/index.type';

import { useParams } from 'react-router-dom';
import { setMode } from 'store/features/orderForm/index.slice';
import FormHeader from 'components/organisms/FormHeader';
import FormContent from 'components/organisms/FormContent';
import useInitializeFormData from 'hooks/useInitializeFormData';
import { getUserAccount } from 'store/features/auth/index.selector';
import BackdropLoader from 'components/atoms/BackdropLoader';
import InfoField from 'components/atoms/InfoField';
import { DARK_STATUS_COLOR } from 'constants/index';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { t } from 'i18next';

const ReOrderForm: React.FC = () => {
  const user = useSelector(getUserAccount);
  const [, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const { data: config } = useGetOrderConfigQuery(
    {
      userId: user?.id,
      role: user?.role,
    },
    { skip: !(user?.id && user?.role) }
  );

  dispatch(setMode(ModeState.REORDER));

  const { id } = useParams<{ id: string }>();
  const idFromParams = parseInt(id, 10) || 0;

  const { data, isLoading, error } = useGetOrderByIdQuery(idFromParams);

  const { isFormDisabled, toggleFormDisabled } = useInitializeFormData(
    dispatch,
    config,
    data,
    isLoading,
    error
  );

  return (
    <Grid container justifyContent="center" height={'100%'}>
      <Grid
        item
        m={{ xs: '0px', md: '60px' }}
        sx={{ width: 960, minWidth: 200 }}
      >
        <RenderByCondition show={[data?.hasExpiredIsolates]}>
          <InfoField
            message={t('orderForm.ExpiredIsolateWarning')}
            color={DARK_STATUS_COLOR.DENIED}
            style={{
              margin: '15px 0 0 0',
              borderRadius: 0,
              fontSize: '15px',
              whiteSpace: 'pre-line',
            }}
          />
        </RenderByCondition>

        <FormHeader />
        <FormContent
          setActiveStep={setActiveStep}
          isFormDisabled={isFormDisabled}
          toggleFormDisabled={toggleFormDisabled}
        />
        <BackdropLoader open={isLoading} />
      </Grid>
    </Grid>
  );
};

export default withLayout(ReOrderForm);
