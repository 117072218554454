import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  CardContent,
  Typography,
  Collapse,
  Box,
  Link,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { OrderActionsProps, OrderCardDetailProps } from './types';
import {
  CenteredBox,
  CustomItems,
  StyledCard,
  StyledMenu,
  StyledMenuItem,
  StyledCircle,
  StyledCardContent,
  StyledCardHeader,
  StyledCardActions,
  StyledForm,
  StyledTypography,
  StyledTextarea,
  StyledErrorText,
} from './styles';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/atoms/Button';
import {
  LetterIcon,
  DropDownIcon,
  AddressIcon,
  ExpandIconOpen,
  ExpandIconClose,
  DocumentIcon,
  EventIcon,
  ExportIcon,
  CancelIcon,
  LeftArrow,
} from 'components/icons';
import SerialTimeline from '../OrderTimeLineSerials';
import TextBadge from 'components/atoms/TextBadge';
import {
  formatDate,
  getDataVisibility,
  getEnvironmentSettings,
  isNotEmptyOrWhitespace,
} from 'utils';
import { useNavigate } from 'react-router';
import { ORDER_STATUSES, PAGES, USER_ROLE } from 'constants/index';
import { getUserRole } from 'store/features/auth/index.selector';
import { useDispatch, useSelector } from 'react-redux';
import { useGenerateRequestLetterMutation } from 'api/docs';
import BackdropLoader from 'components/atoms/BackdropLoader';
import RenderByRole from 'components/atoms/RenderByRole';
import {
  resetAddressManagementAll,
  setCustmerId as setCustomerId,
} from 'store/features/addressesManager/index.slice';
import { clearOrderForm } from 'store/features/orderForm/index.slice';
import useToast from 'hooks/useToast';
import CustomDialog from 'components/molecules/Dialog';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import theme from 'theme';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { OrderSource } from 'enums';
import EmailIcon from 'components/icons/EmailIcon';
import PopupConfirmation from '../PopupConfirmation';
import { IActionButton } from '../PopupConfirmation/types';

const {
  DASHBOARD_SHIPPING_DATE_VISIBILITY,
  DASHBOARD_ADDRESSE_VISIBILITY,
  DASHBOARD_FARM_NAME_VISIBILITY,
} = getDataVisibility();

const {
  COMPLETE_DOC_PROCESS_VISIBILITY,
  REORDER_REMINDER_VISIBILITY,
  SERIALS_SELECT_VISIBILITY,
  CREATE_ORDER_BY_ROLE_VISIBILITY,
  JDE_ID_VISIBILITY,
} = getEnvironmentSettings();

const useCommonLogic = () => {
  const userRole = useSelector(getUserRole);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectToShippingSites = (id: number, custmerId: number) => {
    dispatch(resetAddressManagementAll());
    dispatch(setCustomerId(custmerId));
    navigate(`${PAGES[userRole].MANAGE_ADDRESSES}/${id}`);
  };
  const redirectToReorder = (id: number) => {
    dispatch(clearOrderForm());
    dispatch(resetAddressManagementAll());
    navigate(`${PAGES[userRole].REORDER_FORM}/${id}`);
  };

  return {
    redirectToShippingSites,
    redirectToReorder,
  };
};

export const OrderActionsUsers: React.FC<OrderActionsProps> = ({
  orderId,
  customerId: custmerId,
  isRequestLetterGenerationEnabled = false,
  reminderModalButtonOnClick,
  showReminderButton,
  actionsDisabled,
  canBeEdited,
}) => {
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const { t } = useTranslation();
  const userRole = useSelector(getUserRole);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { redirectToShippingSites, redirectToReorder } = useCommonLogic();

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const redirectToDetailsOrder = (id: number) => {
    dispatch(resetAddressManagementAll());
    navigate(`${PAGES[userRole].ORDER_DETAILS}/${id}`);
  };

  const [
    generateRequestLetter,
    {
      isSuccess: generateRequestLetterSuccess,
      isError: generateRequestLetterIsError,
      isLoading: generateRequestLetterIsLoading,
    },
  ] = useGenerateRequestLetterMutation();

  const callGenerateRequestLetter = async (orderId: number) => {
    // Close menu
    handleMenuClose();

    // Call API
    await generateRequestLetter({ orderId });
  };

  useEffect(() => {
    if (generateRequestLetterSuccess) {
      toast.success(t('messages.requestLetterGenerationSuccess'), {});
    } else if (generateRequestLetterIsError) {
      toast.error(t('messages.requestLetterGenerationError'), {});
    }
  }, [generateRequestLetterSuccess, generateRequestLetterIsError]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      spacing={1}
      sx={{ pt: 1, pb: 1 }}
    >
      {canBeEdited && (
        <Grid item>
          <CustomButton
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
            onClick={handleMenuOpen}
          >
            {t('orderForm.tools')} &nbsp;
            <DropDownIcon />
          </CustomButton>
          <StyledMenu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Grid>
              <RenderByCondition show={[COMPLETE_DOC_PROCESS_VISIBILITY]}>
                <StyledMenuItem
                  disabled={
                    !isRequestLetterGenerationEnabled || actionsDisabled
                  }
                  onClick={async () => await callGenerateRequestLetter(orderId)}
                >
                  <Typography variant="body1">
                    {t('orderForm.generateTheLetter')}
                  </Typography>
                  <LetterIcon />
                </StyledMenuItem>
                <StyledMenuItem
                  disabled={actionsDisabled}
                  onClick={() => redirectToShippingSites(orderId, custmerId)}
                >
                  <Typography variant="body1">
                    {t('orderForm.manageAddress')}
                  </Typography>
                  <AddressIcon />
                </StyledMenuItem>
              </RenderByCondition>
              <RenderByCondition
                show={[REORDER_REMINDER_VISIBILITY && showReminderButton]}
              >
                <StyledMenuItem
                  disabled={actionsDisabled}
                  onClick={() => {
                    if (reminderModalButtonOnClick) {
                      handleMenuClose();
                      reminderModalButtonOnClick();
                    }
                  }}
                >
                  <Typography variant="body1">
                    {t('dashboard.reorderReminder')}
                  </Typography>
                  <EventIcon />
                </StyledMenuItem>
              </RenderByCondition>
            </Grid>
          </StyledMenu>
        </Grid>
      )}

      <Grid item>
        <CustomButton
          variant="outlined"
          sx={{ backgroundColor: 'white' }}
          onClick={() => redirectToDetailsOrder(orderId)}
        >
          {t('orderForm.viewDetails')}
        </CustomButton>
      </Grid>
      {canBeEdited && (
        <RenderByRole
          userRole={userRole}
          allowedRoles={[USER_ROLE.VET, USER_ROLE.TM]}
        >
          <Grid item>
            <CustomButton
              onClick={() => redirectToReorder(orderId)}
              sx={{ marginRight: '1.8rem' }}
            >
              {t('orderForm.reorder')}
            </CustomButton>
          </Grid>
        </RenderByRole>
      )}

      <BackdropLoader open={generateRequestLetterIsLoading} />
    </Grid>
  );
};

export const OrderActionsAdmins: React.FC<OrderActionsProps> = ({
  orderId,
  orderStatus,
  customerId: custmerId,
  actionsDisabled,
  handleCancelOrderAction,
  handleSignatureFollowUpAction,
  canBeEdited,
  signatureReminderInfo,
}) => {
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const { t } = useTranslation();
  const userRole = useSelector(getUserRole);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSMup = useMediaQuery(theme.breakpoints.up('sm'));
  const { redirectToShippingSites, redirectToReorder } = useCommonLogic();

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const redirectToDetailsOrder = (id: number) => {
    dispatch(resetAddressManagementAll());
    navigate(`${PAGES[userRole].ORDER_DETAILS}/${id}`);
  };

  const redirectToCompleteDoc = (id: number) => {
    navigate({
      pathname: `${PAGES[userRole].COMPLETE_DOC}/${id}`,
      search: `?stateId=${null}`,
    });
  };

  const getToolsByRole = (userRole) => {
    if (userRole === USER_ROLE.REGULATORY) {
      return [
        {
          label: t('orderForm.completeDocuments'),
          action: () => redirectToCompleteDoc(orderId),
          icon: <DocumentIcon />,
        },
        {
          label: t('global.export'),
          action: () => {
            console.log('export');
          },
          icon: <ExportIcon />,
        },
      ];
    }
    if (userRole === USER_ROLE.CSS) {
      return [
        {
          label: t('global.cancel'),
          action: () => setIsCancelPopupOpen(true),
          icon: <CancelIcon style={{ marginRight: '4px' }} />,
        },
        {
          label: t('dashboard.followUp'),
          action: () => setIsFollowUpConfirmPopupOpen(true),
          icon: <EmailIcon />,
          disabled: orderStatus !== ORDER_STATUSES.INITIATED,
        },
      ];
    }
    if (userRole === USER_ROLE.TM) {
      return [
        {
          label: t('orderForm.manageAddress'),
          action: () => redirectToShippingSites(orderId, custmerId),
          icon: <AddressIcon />,
        },
      ];
    }

    return [];
  };

  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const closeCancelOrderPopup = () => {
    setIsCancelPopupOpen(false);
  };

  const validationSchema = Yup.object({
    comment: Yup.string().required(t('errorMessages.fieldRequired')),
  });

  const cancelOrderFormInitialValues = {
    comment: '',
  };
  const formik = useFormik({
    initialValues: cancelOrderFormInitialValues,
    validationSchema,
    onSubmit: (values) => {
      closeCancelOrderPopup();
      handleMenuClose();
      handleCancelOrderAction(orderId, values.comment);
    },
  });

  const [isFollowUpConfirmPopupOpen, setIsFollowUpConfirmPopupOpen] =
    useState(false);

  const handleFollowUpConfirmClick = () => {
    setIsFollowUpConfirmPopupOpen(false);
    handleMenuClose();
    handleSignatureFollowUpAction(orderId);
  };

  //
  const signatureReminderPopUpButtons: Array<IActionButton> = [
    {
      title: t('orderForm.confirm'),
      onClick: () => {
        handleFollowUpConfirmClick();
      },
      variant: 'contained',
    },
    {
      title: t('orderForm.cancel'),
      onClick: () => {
        setIsFollowUpConfirmPopupOpen(false);
      },
      variant: 'outlined',
      icon: <LeftArrow />,
    },
  ];

  const signatureReminderText = useMemo(() => {
    return signatureReminderInfo.createdBy
      ? t('dashboard.signatureReminderTooltip', {
          createdBy: signatureReminderInfo.createdBy,
          date: formatDate(signatureReminderInfo.sentDate),
        }) +
          ' ' +
          t('dashboard.followUpConfirmationQuestion', { orderId: orderId })
      : t('dashboard.followUpConfirmationQuestion', { orderId: orderId });
  }, [signatureReminderInfo, t, orderId]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
        sx={{ pt: 1, pb: 1 }}
      >
        {canBeEdited && (
          <Grid item>
            <CustomButton
              variant="outlined"
              sx={{ backgroundColor: 'white' }}
              onClick={handleMenuOpen}
            >
              {t('orderForm.tools')} &nbsp;
              <DropDownIcon />
            </CustomButton>

            <StyledMenu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {getToolsByRole(userRole).map((tool) => (
                <StyledMenuItem
                  disabled={actionsDisabled || tool?.disabled}
                  onClick={tool.action}
                  key={tool.label}
                >
                  <Typography variant="body1">
                    <Link>{tool.label}</Link>
                  </Typography>
                  {tool.icon}
                </StyledMenuItem>
              ))}
            </StyledMenu>
          </Grid>
        )}

        <Grid item>
          <CustomButton
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
            onClick={() => redirectToDetailsOrder(orderId)}
          >
            View Details
          </CustomButton>
        </Grid>
        <RenderByCondition
          show={[
            !(!CREATE_ORDER_BY_ROLE_VISIBILITY && userRole === USER_ROLE.TM),
          ]}
        >
          <RenderByRole userRole={userRole} allowedRoles={[USER_ROLE.TM]}>
            <Grid item>
              <CustomButton
                sx={{ marginRight: '1.8rem' }}
                onClick={() => redirectToReorder(orderId)}
              >
                {t('orderForm.reorder')}
              </CustomButton>
            </Grid>
          </RenderByRole>
        </RenderByCondition>
      </Grid>

      <CustomDialog
        modalTitle={t('dashboard.cancelPopUpTitle')}
        handleClose={closeCancelOrderPopup}
        open={isCancelPopupOpen}
      >
        <StyledTypography variant="body1" display="inline">
          {t('dashboard.cancelCommentText')}
        </StyledTypography>

        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid
            container
            direction="column"
            columnSpacing={2}
            sx={{ width: isSMup ? '100%' : '55%' }}
          >
            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <StyledTextarea
                name={'comment'}
                value={formik.values.comment}
                onChange={formik.handleChange}
                placeholder={t('global.comment')}
                onBlur={formik.handleBlur}
              />
              <RenderByCondition
                show={[
                  Boolean(formik.touched.comment && formik.errors.comment),
                ]}
              >
                <StyledErrorText size={'small'}>
                  {formik.errors.comment}
                </StyledErrorText>
              </RenderByCondition>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              marginTop="1rem"
              marginBottom="0rem"
              paddingBlock="0rem"
            >
              <Grid item marginRight="0.25rem">
                <CustomButton
                  size="medium"
                  variant="outlined"
                  onClick={closeCancelOrderPopup}
                >
                  {t('orderForm.cancel')}
                </CustomButton>
              </Grid>
              <Grid item columnSpacing={1}>
                <CustomButton
                  size="medium"
                  type="submit"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.dirty
                  }
                >
                  {t('orderForm.save')}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>
      </CustomDialog>
      <PopupConfirmation
        isOpen={isFollowUpConfirmPopupOpen}
        handleClose={() => setIsFollowUpConfirmPopupOpen(false)}
        title={t('dashboard.signatureReminderPopUpTitle')}
        description={signatureReminderText}
        actionButtons={signatureReminderPopUpButtons}
      />
    </>
  );
};

export const OrderCardDetail: React.FC<OrderCardDetailProps> = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const {
    data,
    orderSerials,
    formattedSubmissionDate,
    formattedConfirmationDate,
    formattedShippingDate,
    numberOfDoses,
    actionButtons,
  } = props;

  const { t } = useTranslation();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const userRole = useSelector(getUserRole);

  let orderID = `${t('orderForm.orderNumber')} : ${data?.id}`;

  if (JDE_ID_VISIBILITY) {
    orderID += ` / ${data?.jdeId ? data?.jdeId : '---'}`;
  }

  const orderConfirmationDate =
    data?.source === OrderSource.OldDataSource
      ? '---'
      : formattedConfirmationDate || t('orderForm.dateConfirmation');

  return (
    <CenteredBox mb={4}>
      <StyledCard sx={{ width: '100%' }}>
        <StyledCardHeader
          title={
            <Grid container alignItems="left" spacing={{ xs: 0, md: 1 }}>
              <Grid item xs>
                <Typography
                  variant="body1"
                  fontWeight={800}
                  paddingLeft="1.25rem"
                  color="primary"
                  pl={{ xs: 0 }}
                >
                  {orderID}
                </Typography>
              </Grid>
            </Grid>
          }
          action={actionButtons}
        ></StyledCardHeader>

        <StyledCardContent>
          <Grid>
            <Box sx={{ width: '100%' }}>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1, md: 1 }}>
                <Grid item xs={12} md={4}>
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <CustomItems>
                        <Typography
                          variant="body1"
                          color="neutral.main"
                          fontWeight={800}
                          display="inline"
                        >
                          {t('orderForm.customerDash')} :
                        </Typography>
                        <Typography display="inline">
                          {' '}
                          {data?.customer?.name}
                        </Typography>
                      </CustomItems>
                    </Grid>
                    <RenderByRole
                      userRole={userRole}
                      allowedRoles={[
                        USER_ROLE.REGULATORY,
                        USER_ROLE.TM,
                        USER_ROLE.CSS,
                      ]}
                    >
                      <Grid item xs={12}>
                        <CustomItems>
                          <Typography
                            variant="body1"
                            color="neutral.main"
                            fontWeight={800}
                            display="inline"
                          >
                            {t('orderForm.attendingVet')} :
                          </Typography>
                          <Typography display="inline">
                            {' '}
                            {data?.vet?.userName}
                          </Typography>
                        </CustomItems>
                      </Grid>
                    </RenderByRole>
                    <RenderByCondition show={[DASHBOARD_FARM_NAME_VISIBILITY]}>
                      <Grid item xs={12}>
                        <CustomItems>
                          <Typography
                            variant="body1"
                            color="neutral.main"
                            fontWeight={800}
                            display="inline"
                          >
                            {t('orderForm.farmName')} :
                          </Typography>
                          <Typography display="inline">
                            {' '}
                            {data?.farmName}
                          </Typography>
                        </CustomItems>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomItems>
                          <Typography
                            variant="body1"
                            color="neutral.main"
                            fontWeight={800}
                            display="inline"
                          >
                            {t('orderForm.farmIDM')} :
                          </Typography>
                          <Typography display="inline">
                            {' '}
                            {isNotEmptyOrWhitespace(data?.farmOfficialNumber)
                              ? data?.farmOfficialNumber
                              : '---'}
                          </Typography>
                        </CustomItems>
                      </Grid>
                    </RenderByCondition>
                    <Grid item xs={12}>
                      <CustomItems>
                        <Typography
                          variant="body1"
                          color="neutral.main"
                          fontWeight={800}
                          display="inline"
                        >
                          {t('orderForm.animalSpecies')} :
                        </Typography>
                        <Typography display="inline">
                          {' '}
                          {data?.animalSpecies}
                        </Typography>
                      </CustomItems>
                    </Grid>
                    <RenderByCondition show={[DASHBOARD_ADDRESSE_VISIBILITY]}>
                      <Grid container direction="row" xs={12}>
                        <Grid item>
                          <CustomItems>
                            <Typography
                              variant="body1"
                              color="neutral.main"
                              fontWeight={800}
                              display="inline"
                            >
                              {t('orderForm.proximityAddressesTitle')} :
                            </Typography>
                          </CustomItems>
                        </Grid>
                        <StyledCircle
                          display="inline"
                          value={data?.nonAdjacentAddressesCount}
                        ></StyledCircle>
                      </Grid>
                    </RenderByCondition>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container direction="column">
                    <Grid container direction="row" xs={12}>
                      <Grid item>
                        <CustomItems>
                          <Typography
                            variant="body1"
                            color="neutral.main"
                            fontWeight={800}
                            display="inline"
                          >
                            {t('orderForm.numberOfIsolates')} :
                          </Typography>
                        </CustomItems>
                      </Grid>
                      <Grid item xs={4}>
                        <StyledCircle
                          display="inline"
                          value={data?.numberOfIsolates}
                        ></StyledCircle>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomItems>
                        <Typography
                          variant="body1"
                          color="neutral.main"
                          fontWeight={800}
                          display="inline"
                        >
                          {t('orderForm.specificProduct')} :
                        </Typography>
                        <Typography display="inline">
                          {' '}
                          {data?.specificProduct}
                        </Typography>
                      </CustomItems>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomItems>
                        <Typography
                          variant="body1"
                          color="neutral.main"
                          fontWeight={800}
                          display="inline"
                        >
                          {t('orderForm.volume')} :
                        </Typography>
                        <Typography display="inline">
                          {' '}
                          {data?.volume} ml
                        </Typography>
                      </CustomItems>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomItems>
                        <Typography
                          variant="body1"
                          color="neutral.main"
                          fontWeight={800}
                          display="inline"
                        >
                          {t('orderForm.numberOfDoses')} :
                        </Typography>
                        <Typography display="inline">
                          {' '}
                          {numberOfDoses == '-1'
                            ? t('global.notApplicable')
                            : numberOfDoses}
                        </Typography>
                      </CustomItems>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomItems>
                        <Typography
                          variant="body1"
                          color="neutral.main"
                          fontWeight={800}
                          display="inline"
                        >
                          {t('orderForm.adjuvant')} :
                        </Typography>
                        <Typography display="inline">
                          {' '}
                          {data?.adjuvant}
                        </Typography>
                      </CustomItems>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <CustomItems>
                        <Typography
                          variant="body1"
                          color="neutral.main"
                          fontWeight={800}
                          display="inline"
                          fontSize={15}
                        >
                          {t('orderForm.orderStatus')} :
                        </Typography>
                        <Typography display="inline">
                          {' '}
                          <TextBadge
                            name={data?.orderStatus?.description}
                            color={data?.orderStatus?.color}
                            variant="body1"
                          />
                        </Typography>
                      </CustomItems>
                    </Grid>

                    <Grid item xs={12}>
                      <CustomItems>
                        <Typography
                          variant="body1"
                          color="neutral.main"
                          fontWeight={800}
                          display="inline"
                          fontSize={15}
                        >
                          {t('orderForm.submissionDate')} :
                        </Typography>
                        <Typography display="inline">
                          {' '}
                          {formattedSubmissionDate || '---'}
                        </Typography>
                      </CustomItems>
                    </Grid>

                    <Grid item xs={12}>
                      <CustomItems>
                        <Typography
                          variant="body1"
                          color="neutral.main"
                          fontWeight={800}
                          display="inline"
                        >
                          {t('orderForm.confirmationDate')} :
                        </Typography>
                        <Typography display="inline">
                          {' '}
                          {orderConfirmationDate}
                        </Typography>
                      </CustomItems>
                    </Grid>
                    <RenderByCondition
                      show={[DASHBOARD_SHIPPING_DATE_VISIBILITY]}
                    >
                      <Grid xs={12}>
                        <CustomItems>
                          <Typography
                            variant="body1"
                            color="neutral.main"
                            fontWeight={800}
                            display="inline"
                            fontSize={15}
                          >
                            {t('orderForm.shippingDate')} :
                          </Typography>
                          <Typography display="inline">
                            {' '}
                            {formattedShippingDate || '---'}
                          </Typography>
                        </CustomItems>
                      </Grid>
                    </RenderByCondition>
                    <Grid item xs={12}>
                      <CustomItems></CustomItems>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </StyledCardContent>
        <StyledCardActions>
          <Grid container alignItems="center" px={'16px'} color="#29306B">
            <Typography variant="h6">
              {t('orderForm.orderTimeLine')}{' '}
            </Typography>
          </Grid>
          <Grid
            container
            alignItems="right"
            justifyContent="right"
            paddingRight="1.5rem"
          >
            <IconButton onClick={handleExpandClick}>
              {expanded ? <ExpandIconClose /> : <ExpandIconOpen />}
            </IconButton>
          </Grid>
        </StyledCardActions>
        <Collapse in={expanded} timeout="auto">
          <CardContent sx={{ m: 2 }}>
            {' '}
            <SerialTimeline
              orderSerials={orderSerials}
              hideSelect={SERIALS_SELECT_VISIBILITY}
            />
          </CardContent>
        </Collapse>
      </StyledCard>
    </CenteredBox>
  );
};

export default OrderCardDetail;
